import * as React from "react"
import Layout from "../../components/layout"
import "@fontsource/raleway/300.css"
import Hero from '../../components/hero'
import Header from '../../components/header'
import CharacterGrid from '../../components/charactergrid'
import InBrief from '../../components/inbrief'
import AdBanner from '../../components/adbanner'
import { StaticImage } from 'gatsby-plugin-image'
import { ImageRight, ImageFull } from '../../components/imageelements'
import { SynopsisAct } from '../../components/synposisstyling'
import Bibliography from '../../components/bibliography.js'
import AudioSet from '../../components/audioset'
import { Helmet } from "react-helmet"
import { AdContainer } from '../../components/adstyling'
import { FunFactsHeader } from '../../components/funfactsstyling'
import { MainBodySection } from '../../components/generalstyling'
import VideoPlayer from '../../components/videoplayer'


const characters = [
  {name: 'Carmen', voice: 'Mezzo-soprano', desc: 'The fiery lead role. A gipsy woman who lives outside of society\'s norms and suffers for it.'},
  {name: 'Don José', voice: 'Tenor', desc: 'A low level infantry officer. A chance encounter with Carmen leads to an infatuation that drives the opera forward.'},
  {name: 'Escamillo', voice: 'Bass-Baritone', desc: 'A toreador (bullfighter)! Something of a local celebrity, extremely self-confident and sexually aggressive.'},
  {name: 'Micaëla', voice: 'Soprano', desc: 'The operatic counter to Carmen: sweet and naive. She is betrothed to Don José prior to the start of the opera.'},
  {name: 'Zuniga', voice: 'Bass', desc: 'Don José’s army boss. Also keen on Carmen.'},
  {name: 'Moralès', voice: 'Baritone', desc: 'A friend and army colleague of Don José.'},
  {name: 'Frasquita', voice: 'Soprano', desc: 'A gipsy friend of Carmen.'},
  {name: 'Mercédès', voice: 'Mezzo-Soprano', desc: 'A gipsy friend of Carmen.'},
  {name: 'Le Dancaire', voice: 'Baritone', desc: 'A gipsy who smuggles goods through the mountains.'},
  {name: 'Le Remendado', voice: 'Tenor', desc: 'A gipsy who smuggles goods through the mountains.'},
 ];


const inbrief = {name: "Carmen", composer: "Georges Bizet", librettist: "Ludovic Halévy and Henri Meilhac", language: "French", date: "March 3rd, 1875", acts: "Four", musiclength: "Two hours and thirty five minutes"};

const bibliography = [ 
  {name: 'Cambridge Opera Handbooks, "Carmen"', writer: 'Susan McClary', url: 'https://amzn.to/3GdRvdh'},
  {name: 'The Birth of an Opera: Fifteen Masterpieces from Poppea to Wozzeck', writer: 'Michael Rose', url: 'https://amzn.to/32QlIAz'},
  {name: 'French Opera: A Short History', writer: 'Vincent Giroud', url: 'https://amzn.to/3rEp3gn'},
  {name: 'The Keys to French Opera in the Nineteenth Century', writer: 'Hervé Lacombe', url: 'https://amzn.to/3dnbKcc'},
  {name: 'Bizet and His World', writer: 'Mina Curtiss', url: 'https://amzn.to/3oqFMlp'},
];

// markup
const CarmenPage = () => {
  return (
    <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Carmen | The Opera 101</title>
          <html lang="en-us" />
          <link rel="canonical" href="https://theopera101.com/operas/carmen" />
          <meta name="description" content="A guide to Bizet's stunner of an opera, Carmen. Including Synopsis, Music & Arias, Fun Facts, Running Time and much more!" />
        </Helmet>
        <Hero title="Carmen" subtitle="An Opera by Georges Bizet"/>
        <MainBodySection>

          <ImageRight maxwidth="300px" margintop="0">
            <StaticImage src="../../images/operas/carmen/poster_carmen.jpg" alt="Prudent-Louis Leray's 1875 Poster for Carmen's Premiere"/>
            <figcaption>Prudent-Louis Leray's 1875 Poster for Carmen</figcaption>
          </ImageRight>

          <p>Carmen’s premiere gave the Parisian audience a massive shock, and it wasn't at all clear it had a future on the operatic stages. A portrayal of class, sex, gender and race that troubled the moral codes to a remarkable extent. Today, it is a mainstay of the opera house, packed with tunes you’ll already know and a dramatic narrative that ticks all the Romantic opera boxes.</p>

          <p>As we’ll explore, this shock wasn’t helped by the venue of the premiere, the Opéra-Comique, which generally staged lighter, ‘morally pure’ works for a genteel audience. Bizet wrote Carmen in the ‘correct form’ for the venue; Opéra comique was the French version of German Singspiel, an opera both sung and spoken. But in content, all was not well. The opera was saved by a raucous reception in Vienna and went on to be a worldwide smash hit, but sadly Bizet died just a few months after Carmen’s premiere and would never know its success.</p>

          <p>As an audience member, none of this matters today. Carmen is a gripping opera with the most magnificent arias. It is a decidedly French opera but one that takes place in Spain and uses considerable elements of the Italian verismo style. It is an avalanche of an evening tumbling from the start to its shocking conclusion.</p>

        </MainBodySection>

        
        <div id="ezoic-pub-ad-placeholder-102"> </div>

          <AdContainer>
            <AdBanner
              style={{ display: 'block' }}
              slot="3461570696"
              format="auto"
              responsive="true"
            />
          </AdContainer>

        <MainBodySection>

          <Header title="In Brief"/>

          <InBrief content={inbrief}/>

        </MainBodySection>


        <div id="ezoic-pub-ad-placeholder-103"> </div>

          <AdContainer>
            <AdBanner
              style={{ display: 'block' }}
              slot="3461570696"
              format="auto"
              responsive="true"
            />
          </AdContainer>

        <MainBodySection>

          <Header title="Versions"/>

          <p>Carmen is relatively unusual in that what you’ll hear varies, not inconsiderably. The vocal score Bizet published in March 1875 differs from the one sold alongside the original performances published in January of the same year, whilst the conductors score from the premiere differs from both! Whether these changes during the rehearsal period were forced upon Bizet by the theatre or his choosing is unknown, so it is hard to know which one represents his true intentions.</p>

          <p>Not only that, he agreed before the second production in Vienna for the spoken dialogue to be replaced by recitative but died before that could be completed. His friend Ernest Guiraud wrote this new music and made the required edits, creating a further version of the opera. There are also versions with completely unrelated Bizet music inserted in to create added ballet sections!</p>

          <p>What you’ll hear in any given opera house varies. Since the premiere, something close to Guiraud’s dialogue-free version has been most common (without the added Ballets!). However, something closer to the original Paris version has recently become more popular using a combination of speech and Guiraud’s recitative.</p>

          <Header title="Characters"/>

          <CharacterGrid rows={characters}/>

        </MainBodySection>

        <div id="ezoic-pub-ad-placeholder-104"> </div>

          <AdContainer>
            <AdBanner
              style={{ display: 'block' }}
              slot="3461570696"
              format="auto"
              responsive="true"
            />
          </AdContainer>

        <MainBodySection>

          <Header title="Synopsis"/>

          <SynopsisAct>Act I - Running Time: 50 mins</SynopsisAct>

          <p>We are in Seville, Spain. The year is roughly 1820. The curtain rises on a town square with a tobacco factory on one side and a guardhouse on the other. Soldiers laze about awaiting the changing of the guard. Micaëla arrives looking for her betrothed Don José. Moralès tells her José will return shortly, but the soldiers scare her away with some pretty gross macho behaviour.</p>

          <p>He soon arrives, and Moralès tells him of Micaëla’s appearance. The factory bell rings, and the women of the factory emerge on their break. Men crowd around to watch them, but the person they’re waiting for is Carmen, who arrives and sings the Habanera (you can learn much more about it below). The men beg her to choose a lover, and she provocatively tosses a flower at the one person ignoring her: José.</p>

          <VideoPlayer src="https://www.youtube.com/embed/KJ_HHRJf0xg?autoplay=1" id="KJ_HHRJf0xg" title="Anna Caterina Antonacci sings the Habanera at the Royal Opera House"/>

          <p>The women, including Carmen, go back to work. Micaëla returns and gives José a letter from his mother. His mother wishes him to return home and marry Micaëla, something Micaëla responds to shyly as she had not realised this was the message she had brought. José is prepared to obey his mother when a major commotion emerges from the factory.</p>

          <p>Carmen has attacked another woman with a knife! Zuniga, a Lieutenant and therefore basically in charge, orders her arrest. She responds mockingly, and José is ordered to tie her hands and keep her under his watch. Left alone together, Carmen seduces him with a song. José falls for her hook, line and sinker, agreeing to free her hands so she can swiftly escape. José is arrested, and the curtain falls!</p> 

        </MainBodySection>

        <div id="ezoic-pub-ad-placeholder-105"> </div>

          <AdContainer>
            <AdBanner
              style={{ display: 'block' }}
              slot="3461570696"
              format="auto"
              responsive="true"
            />
          </AdContainer>

        <MainBodySection>

          <SynopsisAct>Act II - Running Time: 40 mins</SynopsisAct>

          <p>The action has moved forward two months, and we are outside Seville, at an inn. Carmen and two friends, Frasquita and Mercédès, are providing entertainment at this inn, including for Zuniga and various of his soldiers. He tells Carmen the excellent news that José has been released from prison.</p>

          <p>Escamillo, a local celebrity toreador, enters with a bit of fanfare and soon sings one of the opera’s biggest numbers, "Votre toast, je peux vous le rendre", better known as the ‘Toreador Song’. He makes a move on Carmen, but she swiftly dismisses him. The inn is closed, and only Carmen and her friends remain.</p>

          <VideoPlayer src="https://www.youtube.com/embed/Vr8sc4EjsTQ?autoplay=1" id="Vr8sc4EjsTQ" title="Kostas Smoriginas sings the Toreador Song in the Royal Operas quirky Barry Kosky production"/>

          <p>They are joined by smugglers who try to get them involved in a scheme to dispose of stolen goods. Carmen’s friends are all in, but she refuses as she wants to wait for José. The smugglers leave as José is seen arriving. Carmen seduces him again, though not without some light taunting thrown in; she has danced with Zuniga. In the distance, the barracks bugle is heard. José says he must leave. Carmen mocks him, telling him that if he loved her, he should stay. He shows her the flower she tossed him in Act I! She remains unmoved, and he continues to refuse her offer.</p>

          <p>Then Zuniga reappears looking for Carmen, and in a fit of jealousy, José gets into a fight with him. Carmen fetches the smugglers who capture Zuniga. Having attacked his boss, José is left with no option but to join Carmen and the smugglers!</p>

        </MainBodySection>

        <div id="ezoic-pub-ad-placeholder-106"> </div>

          <AdContainer>
            <AdBanner
              style={{ display: 'block' }}
              slot="3461570696"
              format="auto"
              responsive="true"
            />
          </AdContainer>

        <MainBodySection>

          <SynopsisAct>Act III - Running Time: 40 mins</SynopsisAct>

          <p>Sometime later, we are in the mountains where Carmen and José return with the smugglers and their stash. Carmen and José squabble, and she flippantly tells him he should go back to his mother.</p>

          <p>Carmen’s friends read their fortunes with tarot cards. They see riches for themselves but death for Carmen and José. They leave with the smugglers to transport the goods leaving José behind on guard duty. Micaëla appears, searching for José, hoping to rescue him from Carmen’s clutches. She sings a beautiful aria, "Je dis que rien ne m'épouvante" (“I say that nothing frightens me”), one that sets her in stark contrast to Carmen. A shot is suddenly heard, and she quickly hides.</p>

          <VideoPlayer src="https://www.youtube.com/embed/lnj8A7gw4QM?autoplay=1" id="lnj8A7gw4QM" title="Barbara Frittoli sings 'Je dis que rien ne m'épouvante' at the Metropolitan Opera"/>
           
          <p>José has fired at someone approaching the camp, who turns out to be Escamillo! Escamillo explains he is looking for Carmen, whom he has fallen for, and the jealous José (again!) gets into a fight with him. The smugglers return, pulling them apart. Escamillo leaves but invites them all to his next bullfight.</p>

          <p>Micaëla is discovered and desperately begs José to leave with her. José refuses but finally agrees when he learns his mother is dying. He departs, ominously promising that Carmen and he will meet again. The curtain falls to the sound of Escamillo singing his famous tune in the distance.</p>

          <SynopsisAct>Act IV - Running Time: 25 mins</SynopsisAct>

          <p>We are back in Seville by the amphitheatre, where a crowd is waiting for the spectacle of the arrival of the bullfighters. Escamillo arrives with none other than Carmen! Escamillo enters the arena for his fight. Frasquita and Mercédès warn Carmen that José has been seen nearby. Carmen shows no fear.</p>

          <p>Everyone enters the arena, but she stays behind, waiting. José enters, and he desperately pleads for her to return to him. She tells him their relationship is over, going as far as to throw the ring he gave her back at him. As she tries to leave, he stabs her to death. The crowd thrill as Escamillo succeeds in his fight. The opera closes on a scene of extreme contrast: José wails his confession as the crowd triumphantly exits the amphitheatre.</p>

          <VideoPlayer src="https://www.youtube.com/embed/t8kT9bS1R78?autoplay=1&start=447" id="t8kT9bS1R78" title="Elina Garanca & Roberto Alagna in the Carmen finale at Opéra national de Paris"/>

        </MainBodySection>

        <div id="ezoic-pub-ad-placeholder-107"> </div>

          <AdContainer>
            <AdBanner
              style={{ display: 'block' }}
              slot="3461570696"
              format="auto"
              responsive="true"
            />
          </AdContainer>

        <MainBodySection>

          <Header title="The Fate Motif"/>

          <p>Carmen is not a motif driven opera per se, but it does contain a few crucial musical motives, including the most significant: what has come to be known as the fate motif. It is first introduced at the end of the prelude and stalks the rest of the opera.</p>

          <p>You can hear it below. It is essentially made up of three parts. There is a loud tremolo in the strings, followed by a short, highly chromatic, melodic theme finishing with two thudding bass drum notes. It has a chilling effect, introduced each time to foreshadow Carmen’s end.</p>

          <AudioSet audiosrc="/audio/carmen/fatemotive.mp3" desc="The Fate Motif from the opening of Carmen"/>

          <p>The name is perhaps unfortunate as the idea that Carmen’s conclusion is fateful is to give a lot of terrible behaviour a bit of a let-off, but that is nevertheless the commonly held name for it!</p>

          <Header title="The Habanera"/>

          <p>The Habanera in Carmen, full name "L'amour est un oiseau rebelle" - "Love Is a Rebellious Bird", is so famous it deserves its own exploration. The Habanera derived from country dance music that travelled from England to France before making its way to the Americas and specifically Cuba, where it found the form we know from Bizet. It was likely reintroduced to Spain by sailors. Its most distinctive element is its dotted rhythm which you can hear below.</p>

          <AudioSet audiosrc="/audio/carmen/habanerarhythm.mp3" desc="The basic rhythm of the Habanera"/>

          <p>It came to Bizet through Sebastián Iradier, a Basque composer, who wrote “El Arreglito”, which Bizet adapted to create Carmen’s song. You can hear a rendering of the original below:</p>

          <VideoPlayer src="https://www.youtube.com/embed/YFDurZPYTDU?autoplay=1" id="YFDurZPYTDU" title="Indira Ferrer-Morató sings ‘El Arreglito’"/>

          <p>Bizet initially believed the tune to be a common folk tune and only learned later that it had been written in 1863, not all that long before Carmen! Bizet wasn’t the only French composer to use the Habanera rhythms in his music: Massenet put one in his opera of 1885, Le Cid, and Saint-Saëns wrote his “Havanaise” in 1887.</p>

        </MainBodySection>

        <div id="ezoic-pub-ad-placeholder-108"> </div>

          <AdContainer>
            <AdBanner
              style={{ display: 'block' }}
              slot="3461570696"
              format="auto"
              responsive="true"
            />
          </AdContainer>

        <MainBodySection>          

          <Header title="Class, Race and Sex"/>

          <p>There’s a lot in Carmen that 21st Century audiences justifiably perceive as problematic but taken in its historical context, there’s a lot of complexity to what is portrayed. Carmen’s demise is often written off as ‘fate’ (see that motif!), but in reality, she is a victim of being an outsider whose atypical power makes her a threat. On all counts, she defies expectations.</p>

          <p>Firstly, she’s a woman. Patriarchal structures of the time dictated that women were objects to be owned and they should be pure and chaste. Bizet introduced the character of Micaëla in part to fulfil this role. Carmen is anything but. She has developed power through her guile and beauty, and she uses that against men.</p>

          <p>Carmen is also working-class; she works in a cigarette factory. That she defiantly maintains her freedom and holds power over Corporal Don José is an insult to both José himself and middle class, opera-going audiences.</p>

          <p>Finally, she is a gipsy, a racial other and in 19th Century French society, the most dangerous kind because gipsies (along with Jews) could pass in society unnoticed. This being 'scary' if you perceive a threat which French society did for various not very good reasons...</p>

          <p>The original Mérimée text is pretty horrendous in its misogynistic portrayal of Carmen (and also it must be said Don José, who as a Spaniard is himself an ‘other’ to a French audience). Bizet’s creation is fortunately far more sympathetic and complex. However, he was still writing in the 19th Century, and all of the above remains true: Carmen is still given some level of blame within the opera when we’d now more justly consider her a victim of the society she lived in.</p>

          <p>What a contemporary director does with all this varies, one director went as far as to have <a href="https://www.telegraph.co.uk/news/2018/01/02/italy-gives-world-famous-opera-carmen-defiant-new-ending-stand/">Carmen kill José</a>. Suffice to say, Carmen’s pretty music can’t hide all its problems. Still, those problems present an opportunity to create a meaningful and emotional exploration for present-day audiences.</p>

        </MainBodySection>

        <div id="ezoic-pub-ad-placeholder-109"> </div>

          <AdContainer>
            <AdBanner
              style={{ display: 'block' }}
              slot="3461570696"
              format="auto"
              responsive="true"
            />
          </AdContainer>

        <MainBodySection>

          <Header title="Adaptations"/>

          <p>Carmen has taken on literally dozens of lives in different adaptations since Bizet wrote his opera. Some of the most interesting are:</p>

          <p>Oscar Hammerstein’s ‘Carmen Jones’, on stage in 1943 and made into a film in 1954, a radical reworking that kept much of Bizet’s music but to all new lyrics and book. The basic narrative is nearly identical, but Hammerstein located the action in the U.S. south during World Way II and utilised an all-black cast.</p>

          <ImageFull>
            <StaticImage src="../../images/operas/carmen/carmen_jones_1954_movie_poster.jpeg" alt="Carmen Jones 1954 Movie Poster"/>
            <figcaption>Carmen Jones 1954 Movie Poster</figcaption>
          </ImageFull>

          <p>Following ‘Carmen Jones’ lineage, utilising an African-American context, but rather more radical, largely dispensing with Bizet’s music, ‘Carmen: A Hip Hopera’ notably starred a young Beyoncé Knowles! It received reasonably tepid reviews when it premiered on tv in 2001 and isn’t the easiest thing to find these days.</p>

          <ImageFull>
            <StaticImage src="../../images/operas/carmen/Carmen,_A_Hip_Hopera_(2001_film)_DVD_boxart.jpeg" alt="Carmen: A Hip Hopera"/>
            <figcaption>Carmen: A Hip Hopera</figcaption>
          </ImageFull>

          <p>Perhaps our favourite is Matthew Bourne’s ‘The Car Man’, which somewhat bizarrely but largely successfully marries a reworking of Bizet’s music with the plot of ‘The Postman Always Rings Twice’ to create a contemporary ballet you won’t soon forget.</p>

          <VideoPlayer src="https://www.youtube.com/embed/P2HDP892vdw?autoplay=1" id="P2HDP892vdw" title="The Car Man | Official Trailer"/>

          <p>For quirkiness, though, the 1990 film ‘Carmen on Ice’ really is a treat. A wordless adaptation that, as you would expect, is entirely danced on ice. The whole thing’s on Youtube, so you can take a peek yourself!</p>
          
          <VideoPlayer src="https://www.youtube.com/embed/MWuHWBAmi9Y?autoplay=1" id="MWuHWBAmi9Y" title="Carmen on Ice"/>

          <Header title="Bibliography"/>

          <Bibliography content={bibliography}/>

        </MainBodySection>
    </Layout>
  )
}

export default CarmenPage
